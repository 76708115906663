<template>
  <div class="contacts">
    <div class="contacts__item">
      <round-icon size="64px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 0c-69.59 0-126 56.41-126 126 0 56.26 82.35 158.8 113.9 196.02 6.39 7.54 17.82 7.54 24.2 0C331.65 284.8 414 182.26 414 126 414 56.41 357.59 0 288 0zm0 168c-23.2 0-42-18.8-42-42s18.8-42 42-42 42 18.8 42 42-18.8 42-42 42zM20.12 215.95A32.006 32.006 0 0 0 0 245.66v250.32c0 11.32 11.43 19.06 21.94 14.86L160 448V214.92c-8.84-15.98-16.07-31.54-21.25-46.42L20.12 215.95zM288 359.67c-14.07 0-27.38-6.18-36.51-16.96-19.66-23.2-40.57-49.62-59.49-76.72v182l192 64V266c-18.92 27.09-39.82 53.52-59.49 76.72-9.13 10.77-22.44 16.95-36.51 16.95zm266.06-198.51L416 224v288l139.88-55.95A31.996 31.996 0 0 0 576 426.34V176.02c0-11.32-11.43-19.06-21.94-14.86z"/></svg>
      </round-icon>
      <span class="contacts__item-title">Adresa</span>
      <a
        class="contacts__item-content">
        {{ contact.address.street }} <br />
        {{ contact.address.zip }} {{ contact.address.city }}
      </a>
    </div>
    <div class="contacts__item">
      <round-icon size="64px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"/></svg>
      </round-icon>
      <span class="contacts__item-title">E-Mail</span>
      <a href="mailto:partex@seznam.cz" class="contacts__item-content">{{ contact.email }}</a>
    </div>
    <div class="contacts__item">
      <round-icon size="64px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"/></svg>
      </round-icon>
      <span class="contacts__item-title">Telefon</span>
      <a v-for="(phone, index) in contact.phone"
         :key="index"
         :href="`tel:+420${phone.replace(/\s/g, '')}`"
         class="contacts__item-content"
         v-html="`+420 ${phone}`" />
    </div>
    <div class="contacts__item">
      <round-icon size="64px">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256,8C119,8,8,119,8,256S119,504,256,504,504,393,504,256,393,8,256,8Zm92.49,313h0l-20,25a16,16,0,0,1-22.49,2.5h0l-67-49.72a40,40,0,0,1-15-31.23V112a16,16,0,0,1,16-16h32a16,16,0,0,1,16,16V256l58,42.5A16,16,0,0,1,348.49,321Z"/></svg>
      </round-icon>
      <span class="contacts__item-title">Otevírací doba</span>
      <span class="contacts__item-content" v-html="contact.hours" />
    </div>
  </div>
</template>

<script>
import RoundIcon from '@/components/RoundIcon.vue';

export default {
  name: 'Contacts',

  components: {
    RoundIcon,
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    contact: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .contacts {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 50px;
    flex-wrap: wrap;

    &__item {
      display: flex;
      min-width: 40%;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      @include bp(midMedium) {
        min-width: 0;
        margin-top: 0;
      }

      &-title {
        font-size: 1.5rem;
        margin-top: 10px;
      }
      &-content {
        text-align: center;
        font-weight: 100;
        font-weight: 400;
      }
    }
  }
</style>
