<template>
  <span :class="`round-icon round-icon--${color}`" :style="`width: ${size};`">
    <slot/>
  </span>
</template>

<script>
export default {
  name: 'RoundIcon',
  props: {
    size: {
      type: String,
      default: '100%',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .round-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 50%;
    box-shadow: 0 15px 35px rgba(50,50,93,.1),0 5px 15px rgba(0,0,0,.07)!important;

    &:after {
      content: "";
      width: 100%;
      padding-top: 100%;
    }

    svg {
      position: absolute;
      width: 50%;
    }

    &--primary {
      background-color: var(--primary-5);

      svg {
        fill: var(--primary);
      }
    }
    &--secondary {
      background-color: var(--secondary-5);

      svg {
        fill: var(--secondary);
      }
    }
    &--tertiary {
      background-color: var(--tertiary-5);

      svg {
        fill: var(--tertiary);
      }
    }
  }
</style>
