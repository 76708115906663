import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

const URL = window.location.hostname;

// firebase init goes here
let config = {
  apiKey: 'AIzaSyCiWIlcvjluPDLSznsyGDFB8eyDeOWPeeQ',
  authDomain: 'partex-bc862.firebaseapp.com',
  databaseURL: 'https://partex-bc862.firebaseio.com',
  projectId: 'partex-bc862',
  storageBucket: 'partex-bc862.appspot.com',
  messagingSenderId: '422194820709',
  appId: '1:422194820709:web:92bb870e3b8a4eab45788c',
  measurementId: 'G-XBZY21QPVP',
  timestampsInSnapshots: true,
};

if (URL.search('kencka') >= 0) {
  config = {
    apiKey: 'AIzaSyCiWIlcvjluPDLSznsyGDFB8eyDeOWPeeQ',
    authDomain: 'partex-bc862.firebaseapp.com',
    databaseURL: 'https://partex-bc862.firebaseio.com',
    projectId: 'partex-bc862',
    storageBucket: 'partex-bc862.appspot.com',
    messagingSenderId: '422194820709',
    appId: '1:422194820709:web:92bb870e3b8a4eab45788c',
    measurementId: 'G-XBZY21QPVP',
    timestampsInSnapshots: true,
  };
}

firebase.initializeApp(config);

firebase.analytics();

Vue.prototype.$analytics = firebase.analytics();

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const { currentUser } = auth;

// firebase collections

// eslint-disable-next-line import/no-mutable-exports
let baseCollection = db.collection('baseData');
// eslint-disable-next-line import/no-mutable-exports
let sectionsCollection = db.collection('sections');

if (URL.search('kencka') >= 0) {
  baseCollection = db.collection('baseData-kencka');
  sectionsCollection = db.collection('sections-kencka');
}
const usersCollection = db.collection('users');

export {
  db,
  auth,
  storage,
  currentUser,
  usersCollection,
  baseCollection,
  sectionsCollection,
};
