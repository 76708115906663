<template>
  <transition name="fade">
    <div
      :class="`message message--${color}`"
      v-show="showMessage">
      <slot/>
      <button
        class="message__close-button"
        @click="showMessage = false">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/></svg>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Message',
  data() {
    return {
      showMessage: true,
    };
  },
  props: {
    color: {
      type: String,
      default: 'success',
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .message {
    margin-bottom: 30px;
    padding: 10px 10px 10px;
    border-radius: 4px;
    box-shadow: $shadow-1;
    position: relative;
    overflow: hidden;

    @include bp(small) {
      height: auto;
      padding: 10px 20px 10px;
    }

    &__close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      box-shadow: none;
      border: none;
      padding: 0;
      margin: 0;
      width: 10px;
      cursor: pointer;
    }

    @each $color, $value in $theme-colors {
      &--#{$color} {
        background-color: lighten(map-get($theme-colors, $color), 20%);

        svg {
          fill: map-get($theme-colors, $color);
        }
      }
    }
  }

  fade-enter-active, .fade-leave-active {
    transition: all .5s ease-in-out;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    box-shadow: 0;
  }
</style>
