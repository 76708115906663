import { render, staticRenderFns } from "./RoundIcon.vue?vue&type=template&id=c528be26&scoped=true&"
import script from "./RoundIcon.vue?vue&type=script&lang=js&"
export * from "./RoundIcon.vue?vue&type=script&lang=js&"
import style0 from "./RoundIcon.vue?vue&type=style&index=0&id=c528be26&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c528be26",
  null
  
)

export default component.exports