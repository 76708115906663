<template>
  <transition name="modal-fade">
    <div v-show="showModal" class="modal">
      <div @click="closeModal()" class="modal__backdrop"></div>
      <div class="modal__body">
        <div @click="closeModal()" class="modal__close">zavřít</div>
        <div class="modal__content" v-html="modalData" />
      </div>
    </div>
  </transition>
</template>

<script>
import { EventBus } from '../event-bus';

export default {
  name: 'procedure',
  data() {
    return {
      showModal: false,
      modalData: Object,
    };
  },

  mounted() {
    EventBus.$on('openModal', (data) => {
      this.modalData = data;
      this.showModal = true;
    });
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .modal {
    z-index: 9999;
    position: fixed;

    &__backdrop {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0,0,0,0.25);
    }

    &__close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #000;
      font-size: 20px;
      cursor: pointer;
    }

    &__body {
      position: fixed;
      background-color: #fff;
      overflow: hidden;
      right: 10px;
      left: 10px;
      top: 30px;
      bottom: 20px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      border-radius: 2px;

      @include bp(small) {
        right: 10vw;
        left: 10vw;
        top: 10vh;
        bottom: 30vh;
      }

      @include bp(medium) {
        right: 20vw;
        left: 20vw;
        top: 10vh;
        bottom: 30vh;
      }
    }
    &__content {
      padding: 10px 20px;
      overflow: scroll;
      height: 100%;

      @include bp(small) {
        padding: 10px 70px;
      }
    }
  }
  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: all 0.5s ease;
  }

</style>
