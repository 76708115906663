<template>
  <div class="footer app-wrapper">
    <div class="footer__background"/>
    <ul v-if="isKencka" class="footer__address">
      <li><h3>Sídlo firmy</h3></li>
      <li>ing. Eva Kencka - ucetnictvi s.r.o.</li>
      <li>Dobrovolného 641/8</li>
      <li>198 00 Praha</li>
      <li><br /></li>
      <li>IČ:  285 20 092</li>
    </ul>
    <ul v-else class="footer__address">
      <li><h3>Sídlo firmy</h3></li>
      <li>PARTEX REAL s.r.o.</li>
      <li>Závada 120</li>
      <li>735 72  Petrovice u Karviné</li>
      <li><br /></li>
      <li>IČ:  268 46 098</li>
    </ul>
    <div class="footer__copyright">
      <span>© 1996 - {{ new Date().getFullYear() }} <a href="https://partexreal.cz">Partex</a> & <a href="https://kencka.cz">Ing. Eva Kencká</a></span>
      <span class="footer__author">Autor webu <a href="https://ondrejzraly.cz/">Ondřej Zralý</a></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    isKencka() {
      return window.location.hostname.search('kencka') >= 0;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .footer {
    position: relative;
    color: #000;
    padding-top: 100px;
    margin-top: 60px;
    padding-bottom: 50px;
    overflow: hidden;

    @include bp(small) {
      margin-top: -80px;
      padding-top: 400px;
    }

    &__background {
      background-color: $gray-200;
      background-size: cover;
      background-position: center;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      transform: skewY(-8deg);
      transform-origin: bottom right;
    }

    &__address {
      bottom: 100px;
      list-style: none;

      @include bp(small) {
        position: absolute;
      }

      h3 {
        margin-block-end: 5px;
      }
    }

    &__copyright {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;
      border-top: 1px solid $gray-500;
    }

    &__author {
      color: rgba(#000, .7);
      font-weight: 200;

      a {
        text-decoration: none;
        font-weight: 400;
      }
    }
  }
</style>
