<template>
  <div>
    <div class="pricelist">
      <div v-for="(item, index) in prices.slice(0, slice)" :key="index" class="pricelist__row">
        <div class="pricelist__column">{{ item.title }}</div>
        <div class="pricelist__column pricelist__column--price">{{ item.price }}</div>
      </div>
    </div>
    <button
      class="btn btn--primary pricelist__show-more"
      v-if="slice < prices.length"
      @click="slice = prices.length">
      Zobrazit více
    </button>
    <a
      class="btn btn--primary pricelist__show-more"
      :href="`#${sectionId}`"
      v-if="slice === prices.length"
      @click="slice = 6">
      Zobrazit méně
    </a>
  </div>
</template>

<script>

export default {
  name: 'Pricelist',
  data() {
    return {
      slice: 6,
    };
  },

  props: {
    prices: Array,
    sectionId: String,
  },
  components: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .pricelist {
    color: #000;
    box-shadow: $shadow-1;

    &__row {
      width: 100%;
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      line-height: 30px;
      padding: 10px 30px;

      @include bp(small) {
        flex-direction: row;
      }

      &:nth-child(2n) {
        background-color: $gray-200;
      }
    }

    &__column--price {
      white-space: nowrap;
      text-align: right;
      font-weight: bold;
    }

    &__show-more {
      display: block;
      margin: 0 auto;
      margin-top: 20px;
    }
  }
</style>
