<template>
  <div id="app">
    <div class="alignment" v-if="loader.home === true">
      <h1>Načítání</h1>
      <div class="pulse-loader"></div>
    </div>
    <router-view v-else />
    <modal/>
  </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import { mapState } from 'vuex';

export default {
  components: {
    Modal,
  },

  computed: {
    ...mapState([
      'loader',
    ]),
  },

  mounted() {
    this.$store.dispatch('loadHome');
    this.$store.dispatch('loadSections');
  },
};

</script>

<style lang="scss">
@import "assets/styles/buttons.scss";

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  font-weight: 400;
}
button {
  line-height: 29px;
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #000;
}
div {
  box-sizing: border-box;
}

a {
  color: var(--primary);
  text-decoration: none;
}

p {
  line-height: 1.2em;
}

ul {
  li {
    line-height: 22px;
  }
}

body,
html {
  padding: 0;
  margin: 0;
}

h1 {
  text-align: center;
  font-weight: 400;
  margin-block-end: 1em;
}

.app-wrapper {
  padding: 0 5vw;

  @include bp(small) {
    padding: 0 10vw;
  }

  @include bp(large) {
    padding: 0 20vw;
  }
}

.hide-on-smallest {
  @media only screen and (max-width: 370px) {
    display: none;
  }
}

.color-blue {
  color: #6c6e99;
}

$loader-size: 150px;
$loader-speed: 2s;

.alignment {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pulse-loader {
  height: $loader-size;
  position: relative;
  width: $loader-size;

  &:before,
  &:after {
    animation-name: pulse-loader;
    animation-iteration-count: infinite;
    animation-duration: $loader-speed;
    box-shadow: 0 0 5px var(--primary);
    border-radius: 50%;
    border: 3px solid var(--primary);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:after {
    animation-delay: $loader-speed / 2;
  }
}

@keyframes pulse-loader {
  0% {
    opacity: 0;
    transform: scale(0)
  }
  60% {
    opacity: 1
  }
  100% {
    opacity: 0;
    transform: scale(1)
  }
}

</style>
