<!-- eslint-disable -->
<template>
  <div class="admin-services__frame">
    <h2>Služby</h2>
    <div class="admin-services__service"
         v-for="(service, index) in model.services"
         :key="index">
      <div>
        <label :for="`titleInput-${service.id}`">Název - </label>
        <input :id="`titleInput-${service.id}`" type="text" class="" v-model="model.services[index].title">
      </div>
      <div>
        <label :for="`titleInput-${service.id}`" :class="`color--${model.services[index].color}`">Barva - </label>
        <select name="cars" :id="`titleInput-${service.id}`" v-model="model.services[index].color">
          <option value="primary">Primární</option>
          <option value="secondary">Sekundární</option>
          <option value="tertiary">Terciární</option>
        </select>
      </div>
      <div>
        <label :for="`positionInput-${service.id}`">Pozice - </label>
        <input :id="`positionInput-${service.id}`" type="number" class="admin-services__position" v-model="model.services[index].position">
      </div>
      <div>
        <label :for="`isEnabledInput-${service.id}`">Zobrazit? - </label>
        <input :id="`isEnabledInput-${service.id}`" type="checkbox" v-model="model.services[index].isEnabled">
      </div>
      <div>
        <label :for="`descriptionInput-${service.id}`">Krátký popis? - </label>
        <input :id="`descriptionInput-${service.id}`" type="text" v-model="model.services[index].description">
      </div>

      <text-editor :content="model.services[index].longDescription"
                   v-if="model.services[index].longDescription"
                   :id="`service-${index}`"
                   @updateSection="updateSection(index, $event)"/>
      <button @click="removeService(index)">Smazat</button>
    </div>
    <button class="admin-services__add-new" @click="addNewService">Přidat další</button>
  </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';

export default {
  name: 'AdminServices',
  components: {
    TextEditor,
  },
  props: {
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      content: '',
      model: {
        services: '',
      },
    };
  },
  mounted() {
    this.model.services = this.services.slice().sort((a, b) => a.position - b.position);
  },
  watch: {
    things: {
      handler(val, oldVal) {
        console.log('a thing changed', val, oldVal);
      },
      deep: true,
    },
  },
  methods: {
    emitServices() {
      this.$emit('services-updated', this.model.services);
    },

    updateSection(index, event) {
      this.model.services[index].longDescription = event;
    },

    removeService(index) {
      console.log(this.model.services);
      this.model.services.splice(index, 1);
      this.emitServices();
    },

    addNewService() {
      this.model.services.push({
        title: 'Název',
        description: 'Nějaký krátký popis',
        longDescription: 'Delší popis nabízené služby',
        position: 9,
        isEnabled: false,
      });
      this.emitServices();
    },
  },
};
</script>

<style lang="scss">
.admin-services {
  width: 100%;

  &__frame {
    border: 1px solid #000;
    padding: 10px;
  }

  &__service {
    box-shadow: $shadow-1;
    padding: 15px;
    margin-bottom: 20px;
  }
}
</style>
