<template>
  <div id="home" class="home">
    <Header v-if="home.slogan"
            :slogan="home.slogan"
            :template="home.template"
            :button="home.callToActionButton">
      <navigation :home="home" :sections="enabledSections"/>
    </Header>
    <div
      class="quick-links"
      :class="{'quick-links__open': areLinksOpen}"
      @click="toggleQuickLinks()">
      <span class="quick-links__fap">Užitečné odkazy</span>
      <ul class="quick-links__content">
        <li>
          <a href="http://wwwinfo.mfcr.cz/ares/ares_es.html.cz" target="_blank" rel="noopener noreferrer">
            ARES - vyhledávání ekonomických subjektů
          </a>
        </li>
        <li>
          <a href="http://adisspr.mfcr.cz/adis/jepo/epo/dpr/apl_ramce.htm?R=/adistc/DphReg?ZPRAC=FDPHI1%26poc_dic=2%26OK=Zobraz"  target="_blank" rel="noopener noreferrer">
            Plátci DPH, čísla účtů pro ekonomickou činnost
          </a>
        </li>
        <li>
          <a href="http://adisreg.mfcr.cz/cgi-bin/adis/idph/int_dp_prij.cgi?ZPRAC=FDPHI1&poc_dic=1" target="_blank" rel="noopener noreferrer">
            Ověřování DIČ v ČR
          </a>
        </li>
        <li>
          <a href="http://ec.europa.eu/taxation_customs/vies/?locale=cs" target="_blank" rel="noopener noreferrer">
            Ověřování DIČ v EU
          </a>
        </li>
        <li>
          <a href="http://www.statnisprava.cz/rstsp/ciselniky.nsf/i/d0027" target="_blank" rel="noopener noreferrer">
            Finanční úřady České republiky
          </a>
        </li>
        <li>
          <a href="http://portal.justice.cz/justice2/uvod/uvod.aspx" target="_blank" rel="noopener noreferrer">
            Justice - oficiální server českého soudnictví
          </a>
        </li>
        <li>
          <a href="https://www.cnb.cz/cs/financni-trhy/devizovy-trh/kurzy-devizoveho-trhu/kurzy-devizoveho-trhu/" target="_blank" rel="noopener noreferrer">
            Kurzy ČNB
          </a>
        </li>
      </ul>
    </div>

    <text-section v-for="(section, index) in enabledSections"
                  :key="section.id"
                  :class="{'text-section--compact-top text-section--first': index === 0}"
                  :id="section.id">
                  <p v-html="section.content" />
                  <pricelist :prices="sortPricelist(section.pricelist)"
                             :sectionId="section.id"
                             v-if="section.pricelist"/>
                  <div class="box__wrapper">
                    <box v-for="(service, index) in section.services"
                        :key="index"
                        color="bordeaux"
                        :service="service">
                    </box>
                  </div>
                  <div v-if="index === 0"
                       class="support">
                       <h1>Přispíváme na babybox</h1>
                    <a href="http://www.babybox.cz/"><img src="../assets/images/podpora.gif" alt=""></a>
                  </div>
    </text-section>

    <text-section
      id="contact"
      class="text-section--v-center text-section--h-center text-section--compact"
    >
      <h1>Kontakty</h1>
      <contacts :contact="home.contact"/>
    </text-section>
    <div>
      <iframe height="450" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2217.328854169868!2d18.541616415414303!3d49.85430337939752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471155a66d44e841%3A0x7905dd07ee9d5165!2sPartex+Real+s.+r.+o.!5e1!3m2!1scs!2scz!4v1535458237839" frameborder="0" allowfullscreen="allowfullscreen" style="border: 0px; width: 100%;"></iframe>
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import TextSection from '@/components/TextSection.vue';
import Contacts from '@/components/Contacts.vue';
import Pricelist from '@/components/Pricelist.vue';
import Box from '@/components/Box.vue';
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import { mapGetters, mapState } from 'vuex';
import { EventBus } from '@/event-bus';

export default {
  name: 'Home',

  components: {
    Header,
    TextSection,
    Navigation,
    Contacts,
    Box,
    Pricelist,
    Footer,
  },

  data: () => ({
    areLinksOpen: false,
  }),

  mounted() {
    window.addEventListener(
      'scroll',
      () => {
        const scrollPosition = (window.pageYOffset
        / (document.body.offsetHeight - window.innerHeight)) * 420;
        if (scrollPosition <= 30) {
          document.body.style.setProperty(
            '--scroll',
            scrollPosition,
          );
        }
      },
      false,
    );
    document.body.classList.add(this.home.template);
  },

  computed: {
    ...mapState([
      'home',
      'sections',
    ]),
    ...mapGetters([
      'getPosts',
    ]),

    enabledSections() {
      return this.sections.filter((section) => section.isEnabled === true);
    },
  },

  methods: {
    sortPricelist(array) {
      return array.slice().sort((a, b) => a.position - b.position);
    },
    openModal(data) {
      EventBus.$emit('openModal', data);
    },

    toggleQuickLinks() {
      this.areLinksOpen = !this.areLinksOpen;
    },
  },
};
</script>

<style lang="scss">
  .quick-links {
    position: fixed;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 250px;
    height: 400px;
    max-height: 80vh;
    border-radius: 5px 5px 0 0;
    background-color: #fff;
    color: #000;
    overflow: hidden;
    font-weight: bold;
    box-shadow: $shadow-1;
    z-index: 10;
    bottom: 0;
    right: 50px;
    padding-top: 40px;
    transition: transform .3s ease-in-out;
    transform: translateY(calc(100% - 40px));

    &__open {
      transform: translateY(0);
    }

    @include bp(small) {
      width: 300px;
      height: 400px;
    }

    &__fap {
      position: absolute;
      background-color: var(--primary);
      color: #fff;
      text-align: center;
      width: 100%;
      height: 40px;
      line-height: 40px;
      top: 0;
      display: block;
    }

    &__content {
      padding: 10px;
      overflow: scroll;
      height: 100%;
      margin: 0;
      list-style: none;

      li {
        padding-top: 10px;
      }
    }
  }
  .support {
    width: 100%;
    text-align: center;
    margin-top: 150px;
  }
</style>
