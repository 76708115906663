<template>
  <scrollactive id="navigation" class="navigation">
    <div class="navigation__logo">
      <div class="navigation__logo-name">
        <a href="#home" class="scrollactive-item navigation__logo-name color--primary">
          {{ home.title }}
        </a>
      </div>
      <div class="navigation__logo-subtitle">{{ home.subTitle }}</div>
    </div>
    <div class="navigation__menu-button" @click="showMenu = !showMenu" v-html="menuButton" />
    <div class="navigation__menu" :class="{ 'navigation__menu--visible': showMenu }">
      <a v-for="(item, index) in sections"
        :key="item.id" @click="showMenu = false"
        :href="`#${item.id}`"
        v-html="item.title" class="scrollactive-item navigation__menu-item"
        :class="{ 'navigation__menu-item--spacer': sections.length > index + 1 }" />
      <!-- <a href="#review" class="scrollactive-item navigation__menu-item">Recenze</a> -->
      <a v-if="home.contactButton"
        :href="`#${home.contactButton.section}`"
        @click="showMenu = false"
        class="scrollactive-item btn btn__menu-item btn--primary">
        {{ home.contactButton.title }}
      </a>
    </div>
    <div class="navigation__notice">
      <div class="navigation__notice-content">
        již {{ new Date().getFullYear() - 1996 }} let jsme tu pro naše klienty
      </div>
    </div>
  </scrollactive>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    msg: String,
    home: {
      type: Object,
      required: true,
    },
    sections: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      scrollPosition: null,
      showMenu: false,
    };
  },

  computed: {
    menuButton() {
      return this.showMenu ? 'ZAVŘÍT' : 'MENU';
    },
  },

  mounted() {
    console.log(this.sections);
    this.setScrollVariable(0);
    window.addEventListener(
      'scroll',
      () => {
        const scrollPosition = (window.pageYOffset
          / (document.body.offsetHeight - window.innerHeight)) * 1200;
        if (scrollPosition >= 0 && scrollPosition <= 30) {
          this.setScrollVariable(scrollPosition);
        } else if (scrollPosition >= 30) {
          this.setScrollVariable(30);
        } else if (scrollPosition <= 0) {
          this.setScrollVariable(0);
        }
      },
      false,
    );
  },

  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

    setScrollVariable(value) {
      document.body.style.setProperty(
        '--scroll-navigation',
        value,
      );
    },
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navigation {
  left: 0;
  top: 0;
  position: fixed;
  right: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-size: 20px;
  padding: 10px 10px;

  @include bp(medium) {
    padding: calc(30px - (var(--scroll-navigation) * 1px) / 2) 20px;
    transform: translateY(calc(30px - (var(--scroll-navigation) * 1px)));
  }

  @include bp(large) {
    padding: calc(30px - (var(--scroll-navigation) * 1px) / 2) 10vw;
  }

  &__logo {
    position: relative;
    color: $green;
    font-size: 7vw;
    line-height: 36px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @include bp(mini) {
      font-size: 30px;
    }

    @include bp(medium) {
      font-size: 40px;
    }

    &-subtitle {
      color: black;
      font-size: 4vw;
      line-height: 21px;

      @include bp(mini) {
        font-size: 15px;
      }

      @include bp(medium) {
        font-size: 20px;
      }
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: none;
    left: 0;
    right: 0;
    min-width: 100%;
    padding: 30px;

    @include bp(medium) {
      flex: 1;
      display: block;
      position: relative;
      text-align: right;
      box-shadow: none;
      min-width: 0;
      top: 0;
      padding: 0;
      flex-direction: row;
    }

    &--visible {
      display: flex;
    }

    &-button {
      cursor: pointer;

      @include bp(medium) {
        display: none;
      }
    }

    .btn {
      margin-top: 8px;

      @include bp(medium) {
        margin-top: 0;
        margin-left: 15px;
        padding: 0 20px;
      }
    }

    &-item {
      padding-top: 10px;
      text-decoration: none;
      color: #000;
      position: relative;
      text-align: center;

      @include bp(medium) {
        padding: 0;
        margin-left: 10px;
      }

      &:after {
        transition: transform .2s ease-in-out;
        transform: scaleX(0);
        transform-origin: left;
        content: "";
        position: absolute;
        height: 1px;
        background-color: var(--primary);
        bottom: 2px;
        left: 0;
        right: 0;
      }

      &--spacer {
        @include bp(medium) {
          &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: rgba(#000, .5);
            right: -6px;
            top: 0;
          }
        }
      }

      &.is-active,
      &:hover {
        color: var(--primary);

        &:after {
          transform: scaleX(1);
        }
      }
    }

  }

  &__notice {
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    height: 30px;
    display: flex;
    justify-content: center;

    &-content {
      background-color: var(--tertiary);
      font-size: 18px;
      padding: 0 20vw;
      border-radius: 0 0 5px 5px;
      color: white;
    }
  }
}
</style>
