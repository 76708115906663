<template>
  <div class="box">
     <h3 class="box__title" :class="`color--${service.color}`">
      <round-icon size="30px" :color="service.color">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"/></svg>
      </round-icon>
      <span v-html="service.title" />
     </h3>
     <p class="box__content" v-html="service.description" />
     <div class="box__footer">
       <a class="btn"
          :class="`btn--${service.color}`"
          @click="openModal(service.longDescription)">Zjistit více</a>
     </div>
  </div>
</template>

<script>
import RoundIcon from '@/components/RoundIcon.vue';
import { EventBus } from '@/event-bus';

export default {
  name: 'Box',

  components: {
    RoundIcon,
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    service: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openModal(data) {
      EventBus.$emit('openModal', data);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .box {
    margin: 10px;
    padding: 20px 10px 10px;
    border-radius: 2px;
    box-shadow: $shadow-1;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;

    @include bp(small) {
      height: auto;
      padding: 40px 20px 30px;
    }

    &__wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: column;

      @include bp(midMedium) {
        flex-direction: row;
      }
    }

    &:hover {
      box-shadow: $shadow-2;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__title {
      font-weight: 400;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 18px;
      display: grid;
      grid-template-columns: 40px 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        ". .";

      .round-icon {
        margin-right: 10px;
        z-index: -1;
        align-self: start;
      }

      span {
        flex: 1;
        align-self: center;
      }
    }

    &__content {
      flex: 1;
    }
  }
</style>
