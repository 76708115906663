<!-- eslint-disable -->
<template>
  <div class="admin-price-list">
    <div class="admin-price-list__row">
      <div>Název</div>
      <div>Cena</div>
      <div>prio</div>
      <div>x</div>
    </div>
    <div class="admin-price-list__row"
         v-for="(price, index) in model.pricelist"
         :key="index"
         :id="`line-${index}`">
      <contenteditable tag="div"
                      :noNL="true"
                      @focus.native="addFocusClass(`line-${index}`)"
                      @blur.native="removeFocusClass(`line-${index}`)"
                      class="admin-price-list__title"
                      @input="emitPricelist"
                      v-model="model.pricelist[index].title" />

      <input @focus="addFocusClass(`line-${index}`)"
             @blur="removeFocusClass(`line-${index}`)"
             type="text"
             v-model="model.pricelist[index].price">

      <input @focus="addFocusClass(`line-${index}`)"
             @blur="removeFocusClass(`line-${index}`)"
             type="number"
             class="admin-price-list__position"
             v-model="model.pricelist[index].position">

      <input @focus="addFocusClass(`line-${index}`)"
             @blur="removeFocusClass(`line-${index}`)"
             type="checkbox"
             v-model="model.pricelist[index].isEnabled">

      <button @click="removePrice(index)">Smazat</button>
    </div>
    <button class="admin-price-list__add-new" @click="addNewPrice">Přidat další</button>
  </div>
</template>

<script>
export default {
  name: 'AdminPriceList',
  components: {
  },
  props: {
    pricelist: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      content: '',
      model: {
        pricelist: this.pricelist.slice().sort((a, b) => a.position - b.position),
      },
    };
  },
  methods: {
    emitPricelist() {
      this.$emit('pricelist-updated', this.model.pricelist);
    },

    removePrice(index) {
      console.log(this.model.pricelist);
      this.model.pricelist.splice(index, 1);
    },

    addNewPrice() {
      this.model.pricelist.push({
        title: 'Název',
        price: 'cena / měsíc',
        position: this.model.pricelist.length + 1,
        isEnabled: false,
      });
      this.emitPricelist();
    },

    addFocusClass(id) {
      document.getElementById(id).classList.add('admin-price-list__row--active');
    },

    removeFocusClass(id) {
      document.getElementById(id).classList.remove('admin-price-list__row--active');
    },
  },
};
</script>

<style lang="scss">
.admin-price-list {
  width: 100%;

  input {
    width: auto;
  }

  &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr min-content 40px min-content min-content;
    grid-template-areas: ". . . . .";
    gap: 10px;
    padding: 7px;

    &--active {
      background-color: #dedede;
    }
  }

  &__title {
    width: 100%;
    overflow: scroll;
    border: 1px solid #000;
    border-radius: 3px;
    white-space: nowrap;
    display: block;
  }

  &__add-new {
    width: 100%;
    display: block;
    margin-top: 15px;
    line-height: 29px;
  }

  &__position {
    width: 40px;
    size: 3;
  }
}
</style>
