<template>
  <div class="text-section app-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'TextSection',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .text-section {
    padding-top: 20vh;
    padding-bottom: 20vh;
    color: #000;

    &--compact {
       min-height: initial;
       padding-bottom: 0;
    }
    &--compact-top {
       min-height: initial;
       padding-top: 0;
    }

    &--first {
      .red & {
        padding-top: 20px;

        @include bp(medium) {
          padding-top: 80px;
        }
      }

      @include bp(small) {
        transform: translateY(calc((var(--scroll) * .3%)));
      }
    }
  }
</style>
