<template>
  <div class="MainX">
    <navigation :home="home" :sections="navigation"/>
    <h1>Přihlášení</h1>
    <form @submit.prevent>

      <label for="email1">Email</label>
        <input v-model.trim="loginForm.email" type="text" placeholder="you@email.com" id="email1" />

        <label for="password1">Password</label>
        <input v-model.trim="loginForm.password"
               type="password" placeholder="******" id="password1" />

        <button @click="login" class="button">Log In</button>

    </form>
    <div v-if="errorMsg !== ''" class="error-msg">
        <p>{{ errorMsg }}</p>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';

const firebase = require('@/firebase');

export default {
  name: 'Login',

  components: {
    Navigation,
  },

  data() {
    return {
      loginForm: {
        email: '',
        password: '',
      },
      performingRequest: false,
      errorMsg: '',
      navigation: [{ title: 'Login' }],
    };
  },

  methods: {
    login() {
      this.performingRequest = true;
      firebase.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password)
        .then((user) => {
          this.$store.commit('setCurrentUser', user.user);
          this.$store.dispatch('fetchUserProfile');
          this.performingRequest = false;
          this.$router.push('/admin');
        }).catch((err) => {
          console.log(err);
          this.performingRequest = false;
          this.errorMsg = err.message;
        });
    },
  },
};
</script>
