<!-- eslint-disable -->
<template>
  <div class="admin-text-section">
    <div>
      <label for="">Název v menu - </label>
      <input type="text" v-model="model.title">
    </div>
    <div>
      <label for="">Zobrazit sekci - </label>
      <input type="checkbox" name="isEnabled" v-model="model.isEnabled">
    </div>
    <div>
      <label for="">Pozice - </label>
      <input type="number" name="isEnabled" v-model="model.position">
    </div>
    <text-editor :content="section.content"
                  :id="section.id"
                  @updateSection="updateSection"/>
    <div v-if="section.pricelist">
      <admin-price-list @pricelist-updated="pushPricelistChange" :pricelist="section.pricelist" />
    </div>

    <div v-if="section.services">
      <admin-services @services-updated="pushServicesChange" :services="section.services" />
    </div>
    <div class="admin-text-section__control">
      <button class="admin-text-section__delete" @click="deleteSectionMethod">Smazat sekci</button>
      <button class="admin-text-section__save" @click="updateSectionMethod">Uložit</button>
    </div>
  </div>
</template>

<script>
import TextEditor from '@/components/TextEditor.vue';
import AdminPriceList from '@/components/AdminPriceList.vue';
import AdminServices from '@/components/AdminServices.vue';

const fb = require('@/firebase');

export default {
  name: 'AdminTextSection',
  components: {
    TextEditor,
    AdminPriceList,
    AdminServices,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      content: this.section.content,
      model: {
        title: this.section.title,
        isEnabled: this.section.isEnabled,
        position: this.section.position,
      },
      pricelistHolder: this.section.pricelist,
      servicesHolder: this.section.services,
    };
  },

  computed: {
  },
  methods: {
    updateSection(data) {
      this.content = data;
    },
    pushPricelistChange(data) {
      this.pricelistHolder = data;
    },

    pushServicesChange(data) {
      this.servicesHolder = data;
    },
    updateSectionMethod() {
      console.log(this.servicesHolder);
      const dataObj = {
        title: this.model.title,
        content: this.content || '',
        isEnabled: this.model.isEnabled,
        position: this.model.position,
      };
      if (this.pricelistHolder && this.pricelistHolder.length > 0) {
        dataObj.pricelist = this.pricelistHolder;
      }

      if (this.servicesHolder && this.servicesHolder.length > 0) {
        dataObj.services = this.servicesHolder;
      }
      console.log(dataObj);
      fb.sectionsCollection.doc(this.section.id).update(
        dataObj,
      ).then(() => {
        console.log('Kategorie byla upravena');
        this.$store.dispatch('loadSections');
      }).catch((err) => {
        console.log(err);
      });
    },

    deleteSectionMethod() {
      let x;
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (confirm('Opravdu chcete sekci smazat?') === true) {
        console.log('delete section');
      } else {
        console.log('Cancelled deletion');
      }
      return x;
    },
  },
};
</script>

<style lang="scss">
.admin-text-section {
  &__control {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
  }

  &__save {
    background-color: rgba(green, .5);
  }

  &__delete {
    background-color: rgba(red, .5);
    margin-right: 10px;
  }
}
</style>
