import Vue from 'vue';
import Vuex from 'vuex';

const firebase = require('@/firebase');

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    home: {},
    sections: [],
    loader: { sections: true, home: true },
  },

  getters: {
    getPosts: (state) => (id) => state.home.filter((post) => state.posts.indexOf(post) < id),
    getPost: (state) => (url) => state.posts.find((post) => post.url === url),
  },

  mutations: {
    ADD_POST: (state, post) => {
      state.posts.push(post);
    },
    SET_HOME(state, data) {
      state.home = data;
    },
    SET_SECTIONS(state, data) {
      state.sections = data;
    },
    changeLoader(state, payload) {
      state.loader[payload.type] = payload.status;
    },
    setCurrentUser(state, val) {
      state.currentUser = val;
    },
    setUserProfile(state, val) {
      state.userProfile = val;
    },
  },

  actions: {
    loadHome({ commit }) {
      commit('changeLoader', { type: 'sections', status: true });
      firebase.baseCollection.doc('home').get().then((doc) => {
        if (doc.exists) {
          commit('SET_HOME', doc.data());
          commit('changeLoader', { type: 'sections', status: false });
        } else {
          console.log('Requested collection does not exist');
        }
      });
    },

    loadSections({ commit }) {
      commit('changeLoader', { type: 'home', status: true });
      firebase.sectionsCollection.get().then((querySnapshot) => {
        const sectionsItems = [];
        querySnapshot.forEach((doc) => {
          const appObj = { ...doc.data(), id: doc.id };
          sectionsItems.push(appObj);
        });
        commit('SET_SECTIONS', sectionsItems.slice().sort((a, b) => a.position - b.position));
        commit('changeLoader', { type: 'home', status: false });
      });
    },
    fetchUserProfile({ commit, state }) {
      firebase.usersCollection.doc(state.currentUser.uid).get().then((res) => {
        commit('setUserProfile', res.data());
      }).catch((err) => {
        console.log(err);
      });
    },
  },
});

firebase.auth.onAuthStateChanged((user) => {
  if (user) {
    store.commit('setCurrentUser', user);
    store.dispatch('fetchUserProfile');

    firebase.usersCollection.doc(user.uid).onSnapshot((doc) => {
      store.commit('setUserProfile', doc.data());
    });
  }
});

export default store;
