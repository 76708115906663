import Vue from 'vue';
import Sticky from 'vue-sticky-directive';

import VueLodash from 'vue-lodash';
import lodash from 'lodash';

import VueScrollactive from 'vue-scrollactive';
import contenteditable from 'vue-contenteditable';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

Vue.use(contenteditable);

Vue.use(VueLodash, { lodash });

Vue.config.productionTip = false;

Vue.config.productionTip = false;

Vue.use(VueScrollactive);

Vue.use(Sticky);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
