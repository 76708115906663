<!-- eslint-disable -->
<template>
  <div class="admin">
    <h1>Administrace</h1>
    <div v-for="(section, index) in sections"
         class="admin__section"
         :key="index">
      <admin-text-section :section="section"/>
    </div>

    <button @click="addNewSection">Přidat novou sekci</button>
  </div>
</template>

<script>
import AdminTextSection from '@/components/AdminTextSection.vue';
import { mapGetters, mapState } from 'vuex';

const fb = require('@/firebase');

export default {
  components: {
    AdminTextSection,
  },
  data() {
    return {
    };
  },

  computed: {
    ...mapState([
      'home',
      'sections',
    ]),
    ...mapGetters([
      'getPosts',
    ]),
  },
  methods: {
    addNewSection() {
      const date = new Date();
      fb.sectionsCollection.add({
        title: 'Nadpis',
        position: 9,
        content: 'Popis',
        isText: true,
        isEnabled: false,
        createdAt: date,
      }).then(() => {
        console.log('Sekce přidána');
        this.$store.dispatch('loadSections');
      }).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style lang="scss">
.admin {
  &__section {
    margin: 20px;
    padding: 30px;
    box-shadow: $shadow-1;
  }
}
</style>
